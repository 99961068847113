import { createApp, defineAsyncComponent } from 'vue';
import store from 'shared/store';
import './print-share-page-block.scss'

function mountPrintPageShareBlocks() {
	const blocks = [...document.querySelectorAll('[data-share-print-page]')];

	blocks.forEach((block) => {
		const app = createApp({
			name: 'PrintSharePageBlockApp',
		});
		app.component('print-share-page-block', defineAsyncComponent(
			() => import('./PrintSharePageBlock.vue')
		));
		app.use(store);
		app.mount(block);
	});
}

mountPrintPageShareBlocks();
